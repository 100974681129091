import CloseIcon from '@mui/icons-material/Close';
import { Card, IconButton, Typography, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { usePromoCodeMutation } from 'hooks/usePromoCodeMutation';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FeatureFlags } from 'utils/featureFlags';
import { ApiPlanRepository } from '../../../services/api/plan/ApiPlanRepository';
import { SubscriptionEntity } from 'services/api/account/interfaces';
import goToCinder from 'utils/goToCinder';

interface LimitedTimeOfferModalProps {
  isOpen: boolean;
  setModalOpen: (state: boolean) => any;
  accountId: number;
  planRepository: ApiPlanRepository;
  planType: string;
  displayPromoSnackbar: (state: boolean) => any;
  promoSnackbarMessage: (state: string) => any;
  subscription: SubscriptionEntity;
}

const LimitedTimeOfferModal: FunctionComponent<LimitedTimeOfferModalProps> = ({
  isOpen,
  setModalOpen,
  planType,
  accountId,
  displayPromoSnackbar,
  promoSnackbarMessage,
  subscription,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: promoCode, data: data, isSuccess, isError } = usePromoCodeMutation();

  useEffect(() => {
    if (isSuccess) {
      if (data['state'] === true) {
        if (data['message'].includes('Has reached max redemptions for this account')) {
          promoSnackbarMessage('Coupon has reached max redemptions for this account');
        } else {
          promoSnackbarMessage(t('settings.plan.promo_snack_bar'));
        }
        displayPromoSnackbar(true);
        setModalOpen(false);
      } else {
        promoSnackbarMessage(data['message']);
      }
      if (isError) {
        promoSnackbarMessage('failure');
      }
    }
  }, [isSuccess]);

  const handleRenewalOffer = () => {
    promoCode({ accountId: accountId, promoCode: 'proposify20off2024', subscriptionUuid: subscription.uuid });
  };

  const evaluatedFeatureFlags = useFeatureFlag([FeatureFlags.billingPage]);
  const newBillingPageFlag = evaluatedFeatureFlags[FeatureFlags.billingPage];

  const handleDowngradeRedirect = () => {
    if (newBillingPageFlag) {
      const urlString = '/settings/billing/' + planType.charAt(0).toUpperCase() + planType.slice(1);
      navigate(urlString);
    } else {
      goToCinder('/settings/plan/' + planType.charAt(0).toUpperCase() + planType.slice(1));
    }
  };

  const StyledButton = styled(Button)(() => ({
    borderRadius: '100px',
    boxShadow: 'none',
    fontWeight: 600,
    textTransform: 'initial',
  }));

  return (
    <Dialog
      open={isOpen}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '24px',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 16px 1px 24px',
        }}
      >
        {t('settings.plan.limited_time_offer_modal.title')}
        <IconButton sx={{ ml: 'auto' }} onClick={() => setModalOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Card sx={{ bgcolor: '#00283a', margin: 2 }}>
        <DialogContent sx={{ pb: 3 }}>
          <Typography sx={{ pb: 2, color: '#ffffff' }} variant="h5">
            {t('settings.plan.limited_time_offer_modal.offer_next_renewal')}
          </Typography>
          <Typography sx={{ pb: 3, color: '#ffffff' }} variant="body1">
            {t('settings.plan.limited_time_offer_modal.offer_content')}
          </Typography>
          <StyledButton autoFocus size="medium" sx={{ color: '#ffffff', bgcolor: '#00B8D1' }} onClick={handleRenewalOffer}>
            {t('settings.plan.limited_time_offer_modal.offer_accept')}
          </StyledButton>
        </DialogContent>
      </Card>
      <DialogActions sx={{ p: 3 }}>
        <StyledButton autoFocus onClick={handleDowngradeRedirect} size="medium" variant="contained" color="primary">
          {t('settings.plan.limited_time_offer_modal.offer_reject')}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default LimitedTimeOfferModal;
