import { Icons } from 'components/icon/icons';
import { helpLink } from 'components/helpbutton/constant';

import { SubmenuItems } from 'components/tabbednavigation/interface';
import { IntegrationsDatas, IntegrationHref } from '../providers/interfaces';
import { capitalizeFirstLetter } from './formatStringHandlers';
import { URLS } from './urls';
import { constructCinderUrl } from './constructCinderUrl';
import { CINDER_URLS } from './cinder-urls';

const { IcoDashboard, IcoContent, IcoTemplates, IcoMetrics, IcoUser, IcoClients, IcoIntegrations, IcoSettings, IcoHelp, IcoProposifyLogo } =
  Icons;

export interface NavigationItemsProps {
  component: any;
  title: string;
  href: string;
  children?: SubmenuItems;
  external?: boolean;
  hidden?: boolean;
  id: string;
}

const navigationItems = [
  {
    component: IcoDashboard,
    title: 'Dashboard',
    href: constructCinderUrl(CINDER_URLS.dashboard),
    external: true,
  },
  {
    component: IcoContent,
    title: 'Content Library',
    href: constructCinderUrl(CINDER_URLS.library),
    external: true,
  },
  {
    component: IcoTemplates,
    title: 'Templates',
    href: constructCinderUrl(CINDER_URLS.templateSaved),
    external: true,
  },
  {
    component: IcoMetrics,
    title: 'Metrics',
    href: constructCinderUrl(CINDER_URLS.metrics),
    external: true,
  },
  {
    component: IcoUser,
    title: 'Users',
    href: constructCinderUrl(CINDER_URLS.user),
    external: true,
  },
  {
    component: IcoClients,
    title: 'Clients',
    href: constructCinderUrl(CINDER_URLS.client),
    external: true,
  },
  {
    component: IcoIntegrations,
    title: 'Integrations',
    href: '/integrations',
    external: true,
    children: [
      {
        title: 'Integrations',
        href: '/integrations',
      },
      {
        title: 'Manage Integrations',
      },
      {
        title: 'Automations',
        href: '/integrations/automations',
      },
      {
        title: 'Workflow Builders',
        href: '/integrations/design',
      },
    ],
  },
  {
    component: IcoSettings,
    title: 'Settings',
    href: constructCinderUrl(CINDER_URLS.settings),
    external: true,
  },
  {
    component: IcoDashboard,
    title: 'Documents',
    href: URLS.pipeline,
    external: false,
    hidden: true,
  },
  {
    component: IcoContent,
    title: 'Content Library',
    href: URLS.library,
    external: false,
    hidden: true,
  },
] as NavigationItemsProps[];

const mobileNavigationItems = [
  ...navigationItems,
  {
    component: IcoHelp,
    title: 'Help',
    href: helpLink,
    external: true,
  },
] as NavigationItemsProps[];

const desktopNavigationItems = [
  {
    component: IcoProposifyLogo,
    title: 'Documents List',
    href: '/',
    id: 'documents-list',
    external: true,
  },
  ...navigationItems,
] as NavigationItemsProps[];

const getIntegrationHref = (integrationName: string): IntegrationHref => {
  const reactIntegrations = ['gong'];

  if (reactIntegrations.includes(integrationName)) return { href: `/integrations/${integrationName}`, external: false };
  else return { href: `${process.env.REACT_APP_LEGACY_APP_URL}/integration/${integrationName}`, external: true, target: '_self' };
};

const getNavigationItemsWithIntegrationChildren = (integrationData: IntegrationsDatas): NavigationItemsProps[] | undefined => {
  if (!integrationData || integrationData.length < 1) return;

  const items: Array<any> = [];

  integrationData.forEach(({ isUserConnected, integrationName }) => {
    if (isUserConnected) {
      items.push({
        title: capitalizeFirstLetter(integrationName),
        ...getIntegrationHref(integrationName),
      });
    }
  });

  return navigationItems.map((item) => {
    if (item.title !== 'Integrations') return item;

    return {
      ...item,
      children: [
        {
          title: 'Integrations',
          href: '/integrations',
        },
        ...(items.length > 0 ? [{ title: 'Manage Integrations', items }] : []),
        {
          title: 'Automations',
          href: '/integrations/automations',
        },
        {
          title: 'Workflow Builders',
          href: '/integrations/design',
        },
      ],
    };
  });
};

export { navigationItems, desktopNavigationItems, mobileNavigationItems, getNavigationItemsWithIntegrationChildren };
