import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { ListingsRepository } from 'services/api/listings/ListingsRepository';
import { LocationDataEntity, StateListing } from 'services/api/listings/interfaces';

type UseListingDataApiParams = {
  listingsRepository: ListingsRepository;
};

type UseLocationDataApiResult = {
  locationData?: LocationDataEntity;
  isLoading: boolean;
  isError: boolean;
  isFetched: boolean;
  getStatesFromCountry: (countryId: number, states: StateListing[]) => StateListing[];
};

type UseLocationDataApi<P> = (params: P) => UseLocationDataApiResult;

export const useLocationData: UseLocationDataApi<UseListingDataApiParams> = ({ listingsRepository }) => {
  const { data, isLoading, isError, isFetched } = useQuery({
    queryKey: QueryKeys.getLocationData,
    queryFn: () => listingsRepository.getLocationData(),
  });

  return {
    locationData: data,
    isLoading,
    isError,
    isFetched,
    getStatesFromCountry: listingsRepository.getStatesFromCountry,
  };
};
