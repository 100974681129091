import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Stack, Skeleton } from '@mui/material';

import { BillingOptionEntity } from 'services/api/account/interfaces';
import { BillingContext } from './billingProvider';

import { BillingOptionSummaryProps } from './interface';
import { BillingCard, BillingCardContent, BillingCardHeader } from './styled';
import { formatAsPrice } from '../utils/utils';

const PlanSummary = ({ billingOptions, handleSetBillingOption }: BillingOptionSummaryProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { currentBillingOption, isPaying, isBillingOptionsFetched } = useContext(BillingContext);

  const renderBillingOptionLabel = (billingOption: BillingOptionEntity): React.ReactNode => {
    return (
      <Stack direction="column">
        <Box>
          <Typography component="span" color="text.primary">
            {`${billingOption.name} ${
              billingOption.isCurrentPlan || billingOption.changePreview === null ? t('settings.billing.current_plan') : ''
            }`}
          </Typography>
        </Box>
        <Stack direction="row" gap="4px" alignItems="flex-start">
          <Typography component="span" variant="body1" color="text.secondary">
            {t('settings.billing.seat_per_month', {
              amount: formatAsPrice(billingOption.monthlyValue || 0, true),
            })}
          </Typography>
          {billingOption.changePreview?.annualDiscount && billingOption.planName !== 'Business' && (
            <Typography component="span" variant="body1" color="secondary">
              {t('settings.billing.annual_discount')}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  };

  return (
    <BillingCard>
      <BillingCardHeader disableTypography title={t('settings.billing.plan_summary')} />
      {currentBillingOption ? (
        <BillingCardContent>
          <Box
            sx={{
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              padding: 2,
              width: '100%',
            }}
          >
            <Typography component="div" variant="h6" color="text.primary">
              {currentBillingOption.name}
            </Typography>
            <Typography component="div" variant="body1">
              {currentBillingOption.userLimit === -1 ? t('settings.billing.unlimited_users') : currentBillingOption.userLimit}{' '}
              {t('settings.billing.users')}
            </Typography>
          </Box>
          {billingOptions.length > 0 ? (
            <FormControl sx={{ gap: 1 }}>
              <FormLabel id="billing-period-label">
                <Typography component="span" color="text.primary">
                  {t('settings.billing.billing_options')}
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="billing-period-label"
                value={currentBillingOption?.planId}
                name="radio-buttons-group"
                sx={{
                  gap: 1,
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const planIdString = (event.target as HTMLInputElement).value;
                  const selectedOption = billingOptions.find((o) => o.planId === parseInt(planIdString));
                  if (selectedOption) {
                    handleSetBillingOption(selectedOption);
                  }
                }}
              >
                {billingOptions.map((billingOption, key) => (
                  <FormControlLabel
                    key={`billing-option-${billingOption.name}-${key}`}
                    value={billingOption.planId}
                    control={<Radio />}
                    label={renderBillingOptionLabel(billingOption)}
                    disabled={billingOption.isCurrentPlan || billingOption.changePreview === null || !isBillingOptionsFetched || isPaying}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          ) : (
            <Skeleton width="100%" height="200px" variant="rectangular" data-testid="skeleton-plan-summary-empty" />
          )}
          <Typography variant="caption" color="text.primary">
            {t('settings.billing.change_plan_anytime')}
          </Typography>
        </BillingCardContent>
      ) : (
        <Stack direction="column" gap={2} width="100%" data-testid="skeleton-plan-summary-loading">
          <Skeleton width="100%" height="88px" variant="rectangular" />
          <Skeleton width="97px" height="24px" variant="rectangular" />
          <Skeleton width="100%" height="24px" variant="rectangular" />
          <Skeleton width="100%" height="24px" variant="rectangular" />
          <Skeleton width="100%" height="24px" variant="rectangular" sx={{ maxWidth: '221px' }} />
        </Stack>
      )}
    </BillingCard>
  );
};

export default PlanSummary;
