import React from 'react';
import { Paragraph } from '../../../components';
import './blockSourceButton.less';
import { Icons } from '../../icon/icons';
import { useDrag } from 'react-dnd';

const { IcoTable, IcoPricingTable } = Icons;

interface TableButtonProps {
  title: string;
  testId?: string;
  isPricingTable?: boolean;
}

const TableButton: React.FunctionComponent<TableButtonProps> = ({ title, isPricingTable, testId }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'TABLE',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  const renderedIcon = isPricingTable ? <IcoPricingTable /> : <IcoTable />;

  return (
    <button data-testid={testId} ref={drag} draggable className="block-source" style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div className="block-source-static-icon">{renderedIcon}</div>
      <Paragraph className="block-source__title" size="sm">
        {title}
      </Paragraph>
    </button>
  );
};

export default TableButton;
TableButton.displayName = 'TableButton';
