import * as React from 'react';
import { FeatureTableProps } from './interface';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  styled,
  Typography,
  Button,
  alpha,
  useTheme,
  Tooltip,
  IconButton,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PaidIcon from '@mui/icons-material/Paid';
import { InfoOutlined } from '@mui/icons-material';
import { useMediaQuery } from 'hooks/useMediaQuery';

import { getPlanButtonLabel, canChangeToPlan, isPlanUpgrade } from '../utils/utils';

import './styles.less';

const FeatureTable: React.FC<FeatureTableProps> = ({
  features,
  planType,
  tiers = [],
  currentPlanTier = '',
  onSwitchPlan = null,
  onSwitchSecondary = null,
}) => {
  const { t } = useTranslation();
  const { isTablet, isMobile } = useMediaQuery();
  const theme = useTheme();

  const indicateAvailability = (featureInPlan: string, isNonInclusive: boolean) => {
    if (!featureInPlan) {
      return <CloseIcon sx={{ color: alpha('#00283A1F', 0.2) }} />;
    }

    if (isNonInclusive) {
      return <PaidIcon />;
    }

    return <CheckIcon sx={{ color: alpha('#00283A8F', 0.56) }} />;
  };

  const getRows = (features) => {
    return features.map(({ name, basic, team, business, isNonInclusive, basicText, teamText, businessText, tooltip }, key) => {
      return {
        key: key,
        name: t(name),
        basic: indicateAvailability(basic, isNonInclusive),
        team: indicateAvailability(team, isNonInclusive),
        business: indicateAvailability(business, isNonInclusive),
        basicText,
        teamText,
        businessText,
        tooltip,
      };
    });
  };

  const getColumns = (title) => {
    if (planType && (isTablet || isMobile)) {
      return [
        {
          title: title,
          dataIndex: planType,
          width: '24px',
          className: 'default',
          align: 'left',
        },
        {
          dataIndex: 'name',
        },
      ];
    }

    return [
      {
        title: title,
        dataIndex: 'name',
        width: '25%',
      },
      {
        dataIndex: 'basic',
        width: '25%',
      },
      {
        dataIndex: 'team',
        width: '25%',
      },
      {
        dataIndex: 'business',
        width: '25%',
      },
    ];
  };

  const TierHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    marginTop: theme.spacing(1),
    gap: theme.spacing(1),
  }));

  const TierButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(1, 3),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    borderRadius: '100px',
    boxShadow: 'none',
    width: '100%',
    fontSize: '14px',
    textTransform: 'initial',
  }));

  const IconWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'center',
    flex: '1 0 0',
  }));

  const CategoryHeader = styled(TableCell)(({ theme }) => ({
    border: 'none',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  }));

  const FeatureName = styled(TableCell)(() => ({
    border: 'none',
    borderTop: '1px solid',
    borderColor: alpha('#000000', 0.12),
    textAlign: 'left',
  }));

  const FeatureCell = styled(TableCell)(({ theme }) => ({
    border: 'none',
    borderTop: '1px solid',
    borderColor: alpha('#000000', 0.12),
    textAlign: 'center',
    padding: theme.spacing(2),
  }));

  const switchPlan = (planName) => {
    if (onSwitchPlan) {
      onSwitchPlan(planName);
    }
  };

  const getButtonLabelMap = () => {
    const labelMap = {
      downgrade: 'settings.plan.button_downgrade_plan_label',
      current: 'settings.plan.button_current_plan_label',
      upgrade: 'settings.plan.button_upgrade_plan_label',
    };

    return labelMap;
  };

  return (
    <TableContainer
      sx={{
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: theme.spacing(5),
        padding: theme.spacing(2, 3, 3),
        overflowX: 'initial',
        md: {
          padding: theme.spacing(0),
        },
      }}
    >
      <Table stickyHeader data-testid="plan-feature-comparison-table">
        {!isMobile && !isTablet ? (
          <TableHead>
            <TableRow sx={{ verticalAlign: 'top' }}>
              <TableCell component="th" sx={{ border: 'none', padding: theme.spacing(2, 0) }}>
                <Typography variant="h5">{t('settings.plan.features_table_header')}</Typography>
              </TableCell>
              {tiers.length > 0 &&
                tiers.map((tier, key) => (
                  <TableCell key={key} component="th" sx={{ border: 'none', padding: theme.spacing(2, 2) }}>
                    <TierHeader>
                      <Typography variant="subtitle2">{t(`settings.plan.${tier}.title`) || tier}</Typography>
                      {onSwitchPlan && (
                        <TierButton
                          size="large"
                          disabled={!canChangeToPlan(tier, currentPlanTier)}
                          variant={tier !== currentPlanTier && !isPlanUpgrade(tier, currentPlanTier) ? 'outlined' : 'contained'}
                          color="primary"
                          sx={{ width: '100%', whiteSpace: 'nowrap' }}
                          onClick={() => switchPlan(tier)}
                        >
                          {t(getPlanButtonLabel(tier, currentPlanTier, getButtonLabelMap()))}
                        </TierButton>
                      )}
                      {tier === 'business' && onSwitchSecondary && (
                        <TierButton
                          size="large"
                          disabled={!canChangeToPlan(tier, currentPlanTier)}
                          variant="outlined"
                          color="primary"
                          sx={{ width: '100%', whiteSpace: 'nowrap' }}
                          onClick={() => onSwitchSecondary(tier)}
                        >
                          {t('settings.plan.button_contact_plan_label')}
                        </TierButton>
                      )}
                    </TierHeader>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
        ) : null}
        {Object.keys(features).map((featureGroupName, key) => {
          const tableColumns = getColumns(t('settings.plan.features.' + featureGroupName));
          const tableRows = getRows(features[featureGroupName]);
          return (
            <TableBody key={`feature-group-${key}`}>
              <TableRow>
                <CategoryHeader
                  component="th"
                  colSpan={tableColumns.length}
                  sx={{
                    paddingTop: key === 0 ? theme.spacing(2) : theme.spacing(5),
                  }}
                >
                  {t('settings.plan.features.' + featureGroupName)}
                </CategoryHeader>
              </TableRow>
              {tableRows.map((row, rowKey) => (
                <TableRow key={`feature-row-${rowKey}`}>
                  {tableColumns.map((column, columnKey) => {
                    return column.dataIndex === 'name' ? (
                      <FeatureName
                        key={`feature-cell-${column.dataIndex}-${columnKey}`}
                        colSpan={column.colSpan || 1}
                        sx={{
                          width: column.width,
                        }}
                      >
                        <Typography component="span" variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                          {row[column.dataIndex]}
                        </Typography>
                        {row.tooltip && (
                          <Tooltip title={t(row.tooltip)}>
                            <IconButton sx={{ padding: 0 }}>
                              <InfoOutlined sx={{ width: '20px', height: '20px', marginLeft: '4px' }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </FeatureName>
                    ) : (
                      <FeatureCell
                        key={`feature-cell-${column.dataIndex}-${columnKey}`}
                        colSpan={column.colSpan || 1}
                        sx={{
                          width: column.width,
                        }}
                      >
                        {row[`${column.dataIndex}Text`] ? (
                          <Typography variant="body2">
                            <Trans i18nKey={row[`${column.dataIndex}Text`]} transSupportBasicHtmlNodes />
                          </Typography>
                        ) : (
                          <IconWrapper>{row[column.dataIndex]}</IconWrapper>
                        )}
                      </FeatureCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          );
        })}
      </Table>
    </TableContainer>
  );
};

export default FeatureTable;
