import { Paper, ThemeProvider } from '@mui/material';
import prismatic from '@prismatic-io/embedded';
import { useEffect } from 'react';
import usePrismaticAuth from './usePrismaticAuth';
import { Container, styled } from '@mui/material';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FeatureFlags } from 'utils/featureFlags';
import integrationsTheme from './theme';
import { automationsPhrases } from './phrases';

const embeddedDivId = 'embedded-marketplace-div';
const EmbeddedDesignerWrapper = styled(Container)(() => ({
  height: '80vh',
  width: '100%',
}));

export const PrismaticAutomationsPage = (): JSX.Element => {
  const { authenticated, userinfo } = usePrismaticAuth();
  const { allowEmbeddedDesigner } = userinfo?.authenticatedUser.customer || { allowEmbeddedDesigner: false };
  const featureFlags = useFeatureFlag([FeatureFlags.prismatic]);
  const isPrismaticEnabled = featureFlags[FeatureFlags.prismatic];

  useEffect(() => {
    if (authenticated && allowEmbeddedDesigner) {
      prismatic.showMarketplace({
        selector: `#${embeddedDivId}`,
        usePopover: false,
        translation: {
          phrases: automationsPhrases,
        },
      });
    }
  }, [authenticated, allowEmbeddedDesigner]);

  return (
    isPrismaticEnabled && (
      <ThemeProvider theme={integrationsTheme}>
        <Paper data-testid="my-embedded-marketplace-div" elevation={0}>
          <EmbeddedDesignerWrapper id={embeddedDivId} maxWidth={false} disableGutters />
        </Paper>
      </ThemeProvider>
    )
  );
};
