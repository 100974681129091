import { AxiosError } from 'axios';
import { apiClient } from 'services/api';
import {
  ApplyPromoCodeApiResponse,
  PlanApiResponse,
  PlanEntity,
  UpdatePlanApiResponse,
  UpdatePlanParam,
} from 'services/api/plan/interfaces';
import { Endpoints } from '../../../utils/endpoints';
import { PlanRepository } from './PlanRepository';

export class ApiPlanRepository implements PlanRepository {
  public async getAccountPlan(accountId: number): Promise<PlanEntity> {
    const {
      is_trial,
      pausable,
      id,
      custom,
      type,
      name,
      can_self_downgrade,
      isSwitchingToTeamPlanADowngrade,
      seats_taken,
      is_trial_expired,
    } = await apiClient.get<PlanApiResponse>(Endpoints.getAccountPlan(accountId));

    return {
      pausable,
      isTrial: is_trial,
      custom,
      name,
      id,
      type,
      canSelfDowngrade: can_self_downgrade,
      isSwitchingToTeamPlanADowngrade,
      seats_taken,
      isTrialExpired: is_trial_expired,
    };
  }

  public async applyPromoCode(accountId: number, promoCode: string, subscriptionUuid: string): Promise<ApplyPromoCodeApiResponse> {
    const response = await apiClient.post<ApplyPromoCodeApiResponse>(Endpoints.applyPromocode(accountId), {
      couponCode: promoCode,
      subscriptionUuid,
    });
    return response;
  }

  public async updatePlan({ accountId, payload }: UpdatePlanParam): Promise<UpdatePlanApiResponse> {
    try {
      const response = await apiClient.patch<UpdatePlanApiResponse>(Endpoints.updatePlan(accountId), payload);
      return response;
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data ? (err.response.data as { status: string; message: string }) : { status: 'error', message: '' };
      return { status: data.status || 'error', message: data.message };
    }
  }
}
