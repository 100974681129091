import { openNotification } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { type Document } from 'services/documents/entities/Document';
import { ApiEditorRepository } from 'services/repositories/implementations/ApiEditorRepository';
import { UpdateDocumentStatusPayload } from 'services/repositories/interfaces/EditorRepository';

interface UseManuallyUpdateDocumentStatusProps {
  documentId: string;
  currentDocumentStatus: Document['status'];
  documentTitle: string;
  onUpdateSuccess: () => Promise<void>;
}

const editorRepository = new ApiEditorRepository();

export function useManuallyUpdateDocumentStatus({
  documentId,
  currentDocumentStatus,
  documentTitle,
  onUpdateSuccess,
}: UseManuallyUpdateDocumentStatusProps) {
  const [availableStatuses, setAvailableStatuses] = useState<Document['status'][]>([]);

  useEffect(() => {
    switch (currentDocumentStatus) {
      case 'Draft':
        setAvailableStatuses(['Sent', 'Won', 'Lost']);
        break;
      case 'Sent':
        setAvailableStatuses(['Draft', 'Won', 'Lost']);
        break;
      case 'Awaiting Signatures':
        setAvailableStatuses(['Won', 'Lost']);
        break;
      default:
        setAvailableStatuses([]);
    }
  }, [currentDocumentStatus]);

  const { t } = useTranslation();

  const mutation = useMutation({
    mutationFn: async (payload: UpdateDocumentStatusPayload) => {
      return editorRepository.updateDocumentStatus(documentId, payload);
    },
    onSuccess: async (data) => {
      openNotification({
        title: t('pipeline.tabs.document_list.more_actions.update_status_success_message_title', {
          documentName: documentTitle,
          newStatus: t(`pipeline.tabs.document_list.${data.status.toLowerCase()}`).toLowerCase(),
        }),
        description: '',
        type: 'success',
      });
      onUpdateSuccess();
    },
    onError: () => {
      openNotification({
        title: t('pipeline.tabs.document_list.more_actions.update_status_error_message_title'),
        description: (
          <>
            {t('pipeline.tabs.document_list.more_actions.update_status_error_message_part_1')}
            <a href="mailto:support@proposify.com">{t('pipeline.tabs.document_list.more_actions.update_status_error_message_part_2')}</a>
          </>
        ),
        type: 'error',
      });
    },
  });

  return { availableStatuses, ...mutation };
}
