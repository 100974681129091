import { CircularProgress, IconButton, ListItem, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Check, MoreVert } from '@mui/icons-material';
import { type Document } from 'services/documents/entities/Document';
import { useManuallyUpdateDocumentStatus } from 'hooks/useManuallyUpdateDocumentStatus';
import { UpdateDocumentStatusPayload } from 'services/repositories/interfaces/EditorRepository';

type MoreDocumentActionProps = {
  documentId: string;
  documentStatus: Document['status'];
  documentTitle: string;
  refreshDocumentList: () => Promise<void>;
};

export function MoreDocumentAction({ documentId, documentStatus, documentTitle, refreshDocumentList }: MoreDocumentActionProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { availableStatuses, mutate, isLoading } = useManuallyUpdateDocumentStatus({
    documentId,
    currentDocumentStatus: documentStatus,
    documentTitle,
    onUpdateSuccess: () => {
      closeStatusMenu();
      return refreshDocumentList();
    },
  });

  const updateDocumentStatus = (status: Document['status']) => {
    return () => {
      mutate({ status: status.toUpperCase() as UpdateDocumentStatusPayload['status'] });
    };
  };

  const handleMoreButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);

    const documentTile = event.currentTarget.closest('.ant-list-item') as HTMLElement;
    documentTile?.classList.add('hovered-tile');
  };

  const closeStatusMenu = () => {
    setAnchorEl(null);

    const documentTile = anchorEl?.closest('.ant-list-item');
    const documentDetails = documentTile?.querySelector('.document-details') as HTMLElement;

    if (documentTile) {
      documentTile.classList.remove('hovered-tile');
    }

    if (documentDetails) {
      documentDetails.classList.remove('hide');
    }
  };

  return (
    <>
      <IconButton disabled={availableStatuses.length === 0} onClick={handleMoreButtonClick} data-testid="show-more-button">
        <MoreVert />
      </IconButton>

      <Menu
        data-testid="status-menu"
        anchorEl={anchorEl}
        open={anchorEl ? true : false}
        onClose={closeStatusMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <ListItem dense>
          <Typography variant="subtitle2" color={theme.palette.text.secondary}>
            {t('pipeline.tabs.document_list.more_actions.update_status')}
          </Typography>
          <CircularProgress size="1em" sx={{ visibility: isLoading ? 'visible' : 'hidden', marginLeft: theme.spacing(1.5) }} />
        </ListItem>
        {[...availableStatuses, ...(['Sent', 'Draft'].includes(documentStatus) ? [documentStatus] : [])].sort().map((status) => (
          <MenuItem dense onClick={updateDocumentStatus(status)} disabled={status === documentStatus || isLoading} key={status}>
            <Check sx={{ visibility: status === documentStatus ? 'visible' : 'hidden', marginRight: theme.spacing(1.5) }} />
            <Typography variant="body1" color="primary">
              {t(`pipeline.tabs.document_list.${status.toLowerCase()}`)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
