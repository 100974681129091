import { createContext, useState } from 'react';
import { BillingOptionEntity } from 'services/api/account/interfaces';
import { AccountRepository } from 'services/api/account/AccountRepository';
import { ListingsRepository } from 'services/api/listings/ListingsRepository';
import { PlanRepository } from 'services/api/plan/PlanRepository';
import { PaymentMethodData, Promo } from './interface';

interface BillingProviderProps {
  accountId: number;
  accountRepository: AccountRepository;
  planRepository: PlanRepository;
  listingsRepository: ListingsRepository;
  setApiErrors: (errors: string[]) => void;
  currentBillingOption: BillingOptionEntity | null | undefined;
  currentPromo: Promo | null | undefined;
  setPromo: (newPromo: Promo | null) => void;
  isBillingOptionsFetched: boolean;
  isDowngrade: boolean;
  children: React.ReactNode;
}

type BillingContextProps = {
  accountId: number;
  accountRepository: AccountRepository;
  planRepository: PlanRepository;
  listingsRepository: ListingsRepository;
  currentBillingOption: BillingOptionEntity | null | undefined;
  currentPromo: Promo | null | undefined;
  currentPaymentMethod: PaymentMethodData | null;
  isBillingOptionsFetched: boolean;
  isDowngrade: boolean;
  isPaying: boolean;
  setIsPaying: (boolean) => void;
  setPromo: (newPromo: Promo | null) => void;
  setApiErrors: (errors: string[]) => void;
  setCurrentPaymentMethod: (data: PaymentMethodData | null) => void;
};

export const BillingContext = createContext<BillingContextProps>({} as BillingContextProps);

export const BillingProvider = ({
  accountId,
  accountRepository,
  planRepository,
  listingsRepository,
  setApiErrors,
  currentBillingOption,
  currentPromo,
  setPromo,
  isBillingOptionsFetched,
  isDowngrade,
  children,
}: BillingProviderProps) => {
  const [isPaying, setIsPaying] = useState<boolean>(false);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<PaymentMethodData | null>(null);
  const contextValue: BillingContextProps = {
    accountId,
    accountRepository,
    planRepository,
    listingsRepository,
    setApiErrors,
    currentBillingOption,
    currentPromo,
    setPromo,
    isBillingOptionsFetched,
    isPaying,
    setIsPaying,
    setCurrentPaymentMethod: (data: PaymentMethodData | null) => setCurrentPaymentMethod(data),
    currentPaymentMethod,
    isDowngrade,
  };
  return <BillingContext.Provider value={contextValue}>{children}</BillingContext.Provider>;
};
